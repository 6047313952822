.App {
  text-align: center;
  position: relative;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 0.7;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(61, 62, 62);
  position: relative;
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

@keyframes logo-heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.App-logo {
  animation: logo-heartbeat 2s ease-in-out infinite;
}

#tsparticles {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

